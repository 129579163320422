<template>
  <div style="background-color: #161d31;">
    <div
      class="h-100"
      style="overflow: hidden;"
    >
      <div>
        <h1
          style="
          font-weight: 500;
          position: fixed;
          color: white;
          font-size: 5.25em;
          z-index: 1;
          text-align: center;
          left: 5%;
          top: 35%
        "
        >Benvenuto in CUGRI</h1>
        <b-button
          size="large"
          style="
          width: 15%;
          padding: 1.5rem;
          position: fixed;
          z-index: 1;
          text-align: center;
          left: 5.3%;
          top: 55%
        "
          @click="login"
        >Accedi</b-button>

        <div
          style="
          position: fixed;
          bottom: 4em;
          left: 5.3%;
          font-size: 1.5em;
          color: white;
        "
        >&copy; C.U.G.R.I</div>
      </div>

    </div>
    <vue-agile
      :nav-buttons="false"
      :autoplay-speed="3000"
      :speed="3000"
      fade
      :pause-on-hover="false"
      :pause-on-dots-hover="false"
      autoplay
      :dots="false"
    >
      <div class="slide">
        <img
          src="@/assets/images/welcome/bg01.jpg"
          style="opacity: 0.375; height: 100vh; width: 100vw;"
          alt="Image"
        >
      </div>
      <div class="slide">
        <img
          src="@/assets/images/welcome/bg02.jpg"
          style="opacity: 0.375; height: 100vh; width: 100vw;"
          alt="Image"
        >
      </div>
      <div class="slide">
        <img
          src="@/assets/images/welcome/bg03.jpg"
          style="opacity: 0.375; height: 100vh; width: 100vw;"
          alt="Image"
        >
      </div>
    </vue-agile>
  </div>

</template>

<script>

import { VueAgile } from 'vue-agile'
import auth from '@/@custom/libs/auth'

export default {
  name: 'Welcome',
  components: {
    VueAgile,
  },
  mounted() {
    auth.signoutRedirectCallback()
  },
  methods: {
    login() {
      this.$store.dispatch('auth/login')
    },
  },
}

</script>
